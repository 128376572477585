import {contentServiceURL, travelExplorerURL, travelExplorerURLV3} from './apiDomain'
import {travelExplorerHttp } from './http'
import {useFetchApi, useFetchPublicApi} from '~/composables/fetch/fetchComposables'

// let client = createHttp(contentServiceURL)
// let clientTE = travelExplorerHttp(travelExplorerURL)
// let clientTEV3 = travelExplorerHttp(travelExplorerURLV3)

export default {
    getTrip(id: any) {
        return useFetchPublicApi.get(`${contentServiceURL}/trips/${id}`, {}, {
            baseURL: process.server ? useRuntimeConfig().localProductContentUrl : useAppConfig().baseApiUrl
        })
    },
    getListTrip(params: any) {
        // const client = createHttp(contentServiceURL)
        return useFetchPublicApi.get(`${contentServiceURL}/trips`, params, {
            baseURL: process.server ? useRuntimeConfig().localProductContentUrl : useAppConfig().baseApiUrl
        })
    },
    downloadBrochure({ idShare, id, type, currency, version }) {
        const clientTE = travelExplorerHttp(travelExplorerURL)
        return version !== 'share'
            ? clientTE.get(`/trips/${id}/download/${type}/${currency}/${version}`)
            : clientTE.get(`/trips/${id}/download/${type}/${currency}/${version}/${idShare}`)
    },
    getTripPlanSummary (id, params) {
        // const clientTE = travelExplorerHttp(travelExplorerURL)
        return useFetchApi.get(`${travelExplorerURL}/${id}/summary`, {params: params}, {
            baseURL: process.server ? useRuntimeConfig().localTravelExplorerUrl : useAppConfig().baseApiUrl
        })
    },
    getDownloadPdfLink({ idShare, id, type, currency, version }) {
        let url = `/rest/v1/integration/trips/pdf/${id}/download/${type}/${currency}/${version}`
        if (idShare != null) {
            url += '/' + idShare
        }

        let t = new Date().getTime()
        url += '?t=' + t
        // console.log('Download Pdf', idShare, id, type, currency, version)

        return url
    },
    downloadPdfWithUserDetail(id, payload) {
        const clientTE = travelExplorerHttp(travelExplorerURL)
        return clientTE.post(`/trips/${id}/download/user-details`, payload)
    },
    getSuggestedTrip(id: any, params: any) {
        // const client = createHttp(contentServiceURL)
        return useFetchPublicApi.get(`${contentServiceURL}/trips/${id}/suggested`, params, {
            baseURL: process.server ? useRuntimeConfig().localProductContentUrl : useAppConfig().baseApiUrl
        })
    },
    settings(id, payload) {
        const clientTE = travelExplorerHttp(travelExplorerURL)
        return clientTE.post(`/trips/${id}/settings`, payload)
    },
    getTripPlanSettings(id, params) {
        const clientTE = travelExplorerHttp(travelExplorerURL)
        return clientTE.get(`/trips/${id}/settings`, { params: params })
    },
    updateConfirmedTravellers(id, data) {
        const clientTE = travelExplorerHttp(travelExplorerURL)
        return clientTE.post(`/trips/${id}/traveller-settings`, data)
    },
    getAgentContact(id) {
        const clientTE = travelExplorerHttp(travelExplorerURL)
        return clientTE.get(`/trips/${id}/agent-contact`)
    },
    // getSharedTrip(id: any) {
        // let cache = (window.__INITIAL_DATA__ && typeof window.__INITIAL_DATA__[id] != 'undefined') ? window.__INITIAL_DATA__[id] : null
        // if (cache && cache !== 'null' && cache.id) {
        //     return cache
        // }
        // const client = createHttp(contentServiceURL)
        // return useFetchPublicApi.get(`${contentServiceURL}/shared-trips/${id}`)
    // },
    getProductPopup(params: any) {
        // const client = createHttp(contentServiceURL)
        return useFetchPublicApi.get(`${contentServiceURL}/trips/${params.tripID}/products/${params.itemID}`, {}, {
            baseURL: process.server ? useRuntimeConfig().localProductContentUrl : useAppConfig().baseApiUrl
        })
    },
    // getSpecialDeals() {
    //     let cache = (window.__INITIAL_DATA__ && typeof window.__INITIAL_DATA__['special-deals'] != 'undefined') ? window.__INITIAL_DATA__['special-deals'] : null
    //     if (cache && cache !== 'null') {
    //         return {
    //             data: {
    //                 data: cache
    //             }
    //         }
    //     }
    //     const client = createHttp(contentServiceURL)
    //     return client.get(`/special-deals`)
    // },
    getTransaction(id) {
        const clientTE = travelExplorerHttp(travelExplorerURL)
        return clientTE.get(`/trips/${id}/transaction-details`)
    },
    postNumOfView(id) {
        const clientTEV3 = travelExplorerHttp(travelExplorerURLV3)
        return clientTEV3.post(`/trips/${id}/view`, {id: id})
    },
    getPriceOfTrip(id: any, params: any) {
        // const client = createHttp(contentServiceURL)
        return useFetchPublicApi.get(`${contentServiceURL}/trips/${id}/price`, params, {
            baseURL: process.server ? useRuntimeConfig().localProductContentUrl : useAppConfig().baseApiUrl
        })
    },
    getPriceOfSharedTrip(id: any, params: any) {
        // const client = createHttp(contentServiceURL)
        return useFetchPublicApi.get(`${contentServiceURL}/shared-trips/${id}/price`, params, {
            baseURL: process.server ? useRuntimeConfig().localProductContentUrl : useAppConfig().baseApiUrl
        })
    }
}
